<template>
	<div class="page-box">
		<div class="tips-box">
			<div class="content-box">
				<p class="content">当前页面无法展示</p>
			</div>
		</div>
	</div>
</template>
 
<script>
	export default {
		name: '',
		data(){
			return {
				
			}
		},
		created() {},
		mounted() {},
		computed: {},
		methods:{
			
		},
		components: {},
		beforeDestroy() {},
		watch: {}
	}
</script>
 
<style scoped  lang="scss">
	.page-box{
		width: 100%;
		height: 100%;
		background-color: #f9fafd;
		position: relative;
		
		.tips-box{
			width: 838px;
			height: 287px;
			background: url(../assets/images/404life.png) no-repeat;
			background-size: 100% 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			
			.content-box{
				width: 100%;
				height: 100%;
				position: relative;
				
				.content{
					position: absolute;
					top: 95px;
					left: 240px;
					font-size: 18px;
				}
			}
		}
	}
	
</style>
